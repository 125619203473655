<template>
  <div class="infocompileIndex">
    <div class="infocompileIndex-title">
      <div
        class="infocompileIndex-title-item"
        @click="closeMenu(item)"
        v-for="(item, index) in btnArr"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="infocompileIndex-content">
      <div class="infocompileIndex-content-searchBox">
        <div class="infocompileIndex-content-searchBox-left">
          <div
            id="text"
            class="infocompileIndex-content-searchBox-left-item"
            style="margin-top: 0px"
          >
            <i class="infocompileIndex-content-searchBox-left-icon atlas"></i>
            <div class="infocompileIndex-content-searchBox-left-text fontColor">
              内容图谱
            </div>
          </div>
          <div class="infocompileIndex-content-searchBox-left-item">
            <i class="infocompileIndex-content-searchBox-left-icon tree"></i>
            <div
              v-if="!infoflag"
              class="infocompileIndex-content-searchBox-left-text"
            >
              信息库树
            </div>
            <el-badge v-if="infoflag" is-dot class="item move"
              ><span @click="infoshow">信息库树</span>
            </el-badge>
          </div>
        </div>
        <div class="infocompileIndex-content-searchBox-center">
          <el-select v-model="value" @change="change()" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <input
            type="text"
            placeholder="反抄袭·信息汇编内容服务"
            v-model="searchValue"
          />
          <div @click="search" class="infocompileIndex-content-searchBox-btn">
            <i class="infocompileIndex-content-searchBox-btn-icon"></i>
          </div>
        </div>
      </div>
      <div
        class="infocompileIndex-content-breadcrumb"
        v-if="breadcrumb.length != 0"
      >
        <div
          class="infocompileIndex-content-breadcrumb-item"
          v-for="(item, index) in breadcrumb"
          :key="index"
        >
          <el-tooltip
            class="item"
            :content="item.title"
            effect="light"
            placement="bottom"
          >
            <span @click="closeWord(item)" class="item-title">
              <el-button :class="item.title == itemflag ? 'color' : ''">{{
                item.title
              }}</el-button>
            </span>
          </el-tooltip>
          <span
            @click="closeWord(item)"
            class="item-num"
            :class="item.title == itemflag ? 'color' : ''"
            >{{ item.num }}</span
          >
          <i @click="close(index)" class="el-icon-close"></i>
        </div>
      </div>

      <div class="infocompileIndex-content-list">
        <div class="infocompileIndex-content-list-title">
          <div class="infocompileIndex-content-list-title-left">
            <!-- <el-checkbox v-model="checked" 
              >全选</el-checkbox
            > -->
            <div class="introduce-checkBox" @click="handleCheckAllChange()">
              <i
                class="introduce-checkBox-icon"
                :class="checked == true ? 'checkBox-checked' : ''"
              ></i>
              <div class="introduce-checkBox-text">全选</div>
            </div>

            <div class="left-item" @click="addAssembly">
              <i class="left-item-icon add"></i>
              <div class="left-item-text">添加汇编</div>
            </div>
            <div class="left-item" @click="addCompileVolume">
              <i class="left-item-icon icon"></i>
              <el-badge :value="assemblyNum" :max="99">
                <div class="left-item-text">汇编成册</div>
              </el-badge>
            </div>
            <div class="left-item" @click="batchDownload">
              <i class="left-item-icon download"></i>
              <div class="left-item-text">批量下载</div>
            </div>
            <div class="left-item" @click="toDisk">
              <i class="left-item-icon collect"></i>
              <div class="left-item-text">我的收藏</div>
            </div>
          </div>
          <div class="infocompileIndex-content-list-title-right">
            <div
              class="right-item"
              v-for="(item, index) in sortArr"
              :key="index"
              @click="handleClick(item)"
            >
              <div
                class="right-item-text"
                :class="item.colorSwitch == true ? 'fontColor' : ''"
              >
                {{ item.title }}
              </div>
              <i
                class="right-item-icon"
                :class="item.sort == true ? 'up' : 'down'"
              ></i>
            </div>
          </div>
        </div>
        <div class="infocompileIndex-content-list-content">
          <div v-for="(v, i) in infoList" :key="i" class="content-item">
            <!-- 转载或原创图标 -->
            <!-- <i v-if="v.firstLaunch == 1" class="content-item-icon reprint"></i>
						<i v-if="v.firstLaunch == 2" class="content-item-icon starting"></i> -->
            <!-- 收藏图标 -->
            <i
              class="content-item-like"
              :class="v.isMark == true ? 'like-red' : 'like'"
              @click="collect(v)"
            ></i>
            <div class="content-item-text">
              <span v-html="v.text"></span
              ><span @click="handleAllText(v)">查看全文</span>
            </div>
            <div class="content-item-introduce">
              <div class="introduce-checkBox" @click="handleChecked(v, i)">
                <i
                  class="introduce-checkBox-icon"
                  :class="v.ischecked == true ? 'checkBox-checked' : ''"
                ></i>
                <div class="introduce-checkBox-text">标准版</div>
              </div>
              <!-- <div class="introduce-radioBox">
                <i
                  class="introduce-radioBox-icon"  :class="v.traceSource == 1 ?'radioBox-checked':''"
                ></i>
                <div class="introduce-radioBox-text">溯源版</div>
              </div> -->
              <div class="introduce-starBox">
                <i class="introduce-starBox-icon"></i>
                <div class="introduce-starBox-text" v-text="v.authority"></div>
              </div>
              <div class="introduce-hotBox">
                <i class="introduce-hotBox-icon"></i>
                <div class="introduce-hotBox-text" v-text="v.hot"></div>
              </div>
              <div class="introduce-lampBox">
                <i class="introduce-lampBox-icon"></i>
                <div class="introduce-lampBox-text" v-text="v.rate"></div>
              </div>
              <div class="introduce-timeBox">
                <i class="introduce-timeBox-icon"></i>
                <div class="introduce-timeBox-text" v-text="v.createTime"></div>
              </div>
              <div class="introduce-titleBox">
                <div class="introduce-titleBox-text" v-text="v.title"></div>
              </div>
            </div>
            <div class="content-item-btnGroup">
              <div class="content-item-btnItem downloadBox">
                <i
                  class="content-item-btnItem-icon download"
                  @click="singleDownload(v)"
                ></i>
                <div
                  class="content-item-btnItem-text"
                  @click="singleDownload(v)"
                >
                  下载
                </div>
              </div>

              <div class="btnGroup-line"></div>
              <div class="content-item-btnItem uploadBox">
                <i
                  class="content-item-btnItem-icon upload"
                  @click="moveTo($event, v)"
                ></i>
                <div
                  class="content-item-btnItem-text"
                  @click="moveTo($event, v)"
                >
                  转存网盘
                </div>
              </div>
              <div class="btnGroup-line"></div>
              <!-- <div class="btnGroup-line"></div> -->
              <!-- <div class="content-item-btnItem relationBox">
                <i class="content-item-btnItem-icon relation"></i>
                <div class="content-item-btnItem-text">关联内容</div>
              </div>
              <div class="btnGroup-line"></div>
              <div class="content-item-btnItem indexBox">
                <i class="content-item-btnItem-icon index"></i>
                <div class="content-item-btnItem-text">索引号</div>
              </div> -->
              <div class="content-item-btnItem copyrightBox">
                <i
                  v-if="v.claim == 1"
                  class="content-item-btnItem-icon copyright"
                ></i>
                <div v-if="v.claim == 1" class="content-item-btnItem-text">
                  待认领
                </div>
                <img
                  v-if="v.claim == 2"
                  src="../../../../assets/images/lanbanquan.png"
                />
                <div v-if="v.claim == 2" class="content-item-btnItem-text">
                  已认领
                </div>
              </div>
              <!-- <div class="btnGroup-line"></div> -->
              <!-- <div class="content-item-btnItem shareBox">
                <i class="content-item-btnItem-icon share"></i>
                <div class="content-item-btnItem-text">分享</div>
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="flag" class="kong">
          <img src="../../../../assets/images/kong.png" />
          <div>暂无搜索结果</div>
        </div>
      </div>
    </div>
    <div class="infocompileIndex-footer">
      <el-pagination
        background
        layout="prev,pager,next"
        prev-text="上一页"
        next-text="下一页"
        :pager-count="5"
        :page-size="pageSize"
        :page-count="pageCount"
        :current-page="pageSize"
        :hide-on-single-page="true"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- 子菜单弹窗 -->
    <div v-if="menuflag" class="infoMian">
      <div class="title">信息库树</div>
      <div class="xian"></div>
      <div @click="infoCancel" class="cancel">
        <i class="el-icon-close"></i>
      </div>
      <el-tree
        class="eltree"
        highlight-current
        :props="props"
        :load="loadNode"
        @node-click="nodeClick"
        lazy
      >
      </el-tree>
    </div>

    <!-- 转存网盘 -->
    <el-dialog title="转存网盘" :visible.sync="movePopup" class="addFil">
      <el-tree
        :expand-on-click-node="false"
        style="180px"
        class="tree filter-tree"
        highlight-current
        accordion
        :indent="16"
        :data="TreeData"
        :auto-expand-parent="false"
        node-key="id"
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            {{ test(node, data) }}
            <el-image
              :src="require(`@/assets/images/disk/Icons/${data.picture}.png`)"
              style="width: 30px; height: 30px; vertical-align: middle"
            ></el-image>
            {{ data.filename }}
          </span>
        </span>
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <button class="footer-btn sure" @click="handleSure">确 定</button>
        <button class="footer-btn" @click="movePopup = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 汇编成册 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="compileVolumeDialog"
      title="汇编成册"
      class="compile-volume"
    >
      <div class="content">
        <div class="content-text">自动生成含目录的资料汇编</div>
      </div>
      <div class="footer">
        <div class="footer-btn" @click="handleCompileVolume">确定</div>
        <div class="footer-btn clear" @click="cleanCompile">清空汇编</div>
        <div class="footer-btn" @click="compileVolumeDialog = false">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  queryOfficicalWord,
  addAssemblys,
  getAssemblyNums,
  fileDownload,
  saveNetdisk,
  Collection,
  writerMergeWord,
  delDocTempInfo,
  downloadFile,
} from "api/copyrightService";
import { directory } from "api/disk";
import qs from "qs";
export default {
  data() {
    return {
      btnArr: [],
      options: [
        {
          value: "选项1",
          label: "全文",
        },
        {
          value: "选项2",
          label: "标题",
        },
        {
          value: "选项3",
          label: "作者",
        },
        {
          value: "选项4",
          label: "来源",
        },
      ],
      value: "选项1",
      searchValue: "",
      breadcrumb: [],
      checked: false,
      //选中的数组
      checkarr: [],
      sortArr: [
        {
          title: "时间",
          sort: true,
          colorSwitch: false,
        },
        {
          title: "权威",
          sort: true,
          colorSwitch: false,
        },
        {
          title: "热度",
          sort: true,
          colorSwitch: false,
        },
      ],
      pageSize: 1,
      pageCount: 10,
      pageNum: 0,
      //信息列表
      infoList: [],
      //分页密钥
      pageUuid: "",
      assemblyNum: null,
      //信息排序
      sort: "",
      //排序类型
      sortType: "create_time",
      //无数据时显示
      flag: false,
      //信息菜单
      infoflag: false,
      //子菜单弹窗
      menuflag: false,
      //子菜单信息
      data: [],
      //二级菜单信息
      second: [],
      //三级菜单信息
      tertiary: [],
      //四级菜单
      four: [],
      //五级菜单
      five: [],
      //六级菜单
      six: [],
      //名称显示
      props: {
        label: "name",
        isLeaf: "leaf",
      },
      //搜索类型
      findField: 1,
      //菜单id
      meunIds: 100,
      //常选词
      itemflag: "",
      //搜索关键词
      key: "",

      //转存网盘弹窗
      movePopup: false,
      checkData: "",
      TreeData: [],
      moveid: "",
      md5: "",
      defaultProps: {
        children: "children",
        label: "filename",
      },
      //添加常用词数量
      totalNum: "",
      //汇编成册弹窗
      compileVolumeDialog: false,
    };
  },
  methods: {
    //选择常用词
    closeWord(item) {
      this.itemflag = item.title;
      this.pageUuid = "";
      this.key = "";
      this.meunIds = item.id;
      this.getList();
    },
    // 是否收藏
    async collect(item) {
      let formData = new FormData();
      formData.append("textMd5", item.docmd5);
      const res = await Collection(formData);
      if (res.code === 200) {
        if (item.isMark == true) {
          this.$message({
            type: "success",
            message: "取消收藏",
          });
        } else {
          this.$message({
            type: "success",
            message: "收藏成功",
          });
        }
        this.getList();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //添加常用词
    addword(name, id) {
      if (this.breadcrumb.length == 5) {
        this.breadcrumb.shift();
        for (var i of this.breadcrumb) {
          if (i.title == name) {
            this.itemflag = name;
            return;
          }
        }

        this.breadcrumb.push({
          num: this.totalNum,
          title: name,
          id: id,
        });
      } else {
        for (var a of this.breadcrumb) {
          if (a.title == name) {
            this.itemflag = name;
            return;
          }
        }
        this.breadcrumb.push({
          num: this.totalNum,
          title: name,
          id: id,
        });
      }
    },
    //删除常用词
    close(i) {
      this.breadcrumb.splice(i, 1);
      this.pageUuid = "";
      this.key = "";
      this.meunIds = 100;
      this.getList();
      this.itemflag = "";
    },
    //点击子菜单
    async nodeClick(data) {
      // this.meunIds = data.id;
      this.pageUuid = "";
      this.key = "";
      this.meunIds = data.id;
      this.itemflag = data.name;
      await this.getList();
      this.addword(data.name, data.id);
    },
    //选择搜索类型
    change() {
      if (event.target.innerText == "全文") {
        this.pageNum = 0;
        this.pageUuid = "";
        this.findField = 1;
        this.getList();
      }
      if (event.target.innerText == "标题") {
        this.pageNum = 0;
        this.pageUuid = "";
        this.findField = 2;
        this.getList();
      }
      if (event.target.innerText == "作者") {
        this.pageNum = 0;
        this.pageUuid = "";
        this.findField = 3;
        this.getList();
      }
      if (event.target.innerText == "来源") {
        this.pageNum = 0;
        this.pageUuid = "";
        this.findField = 4;
        this.getList();
      }
    },
    //跳转网盘
    toDisk() {
      this.$store.state.sidebarActive = 77;
      this.$router.push({
        name: "disk",
      });
    },
    //获取分级菜单
    async loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve(this.data);
      }
      if (node.level == 1) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.second = res.data;
          // for(var a of this.second){
          // 	const data = {
          // 		id : a.id
          // 	}
          // 	let res = await list(qs.stringify(data))
          // 	if(res.code ==2001){
          // 		a.leaf = true;
          // 	}
          // }
          // console.log(res.data)
          return resolve(this.second);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 2) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.tertiary = res.data;
          return resolve(this.tertiary);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 3) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.four = res.data;
          return resolve(this.four);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 4) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.five = res.data;
          return resolve(this.five);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
      if (node.level == 5) {
        const data = {
          id: node.data.id,
        };
        let res = await list(qs.stringify(data));
        if (res.code == 200) {
          this.six = res.data;
          return resolve(this.six);
        }
        if (res.code == 2001) {
          return resolve([]);
        }
      }
    },
    //显示弹窗
    infoshow() {
      this.menuflag = true;
    },
    //关闭菜单弹窗
    infoCancel() {
      this.menuflag = false;
      this.infoflag = false;
      //this.pageUuid = "";
      //this.key = "";
      //this.meunIds = 100;
      //this.getList();
      //this.itemflag = ''
    },
    //获取子菜单
    async closeMenu(item) {
      this.itemflag = item.name;
      let data = {
        id: item.id,
      };
      let res = await list(qs.stringify(data));
      this.data = res.data;
      if (this.data == null) {
        this.infoflag = false;
      } else {
        this.infoflag = true;
      }
      this.pageUuid = "";
      this.key = "";
      this.meunIds = item.id;
      await this.getList(); //异步任务   使用await转成同步任务改变执行顺序
      this.addword(item.name, item.id); //同步任务
    },
    //搜索信息
    search() {
      this.key = this.searchValue;
      this.pageUuid = "";
      this.getList();
    },
    //获取信息
    async getList() {
      let data = {
        pageNo: this.pageNum,
        key: this.key,
        meunIds: this.meunIds,
        pageUuid: this.pageUuid,
        sort: this.sort,
        labelId: "",
        searchType: 0,
        sortType: this.sortType,
        mapId: "",
        findField: this.findField,
      };
      let res = await queryOfficicalWord(qs.stringify(data));
      if (res.code == 200) {
        this.flag = false;
        this.infoList = res.data.data;
        this.totalNum = res.data.totalNum;
        this.pageUuid = res.data.page_uuid;
        this.pageCount = Math.ceil(res.data.totalNum / 10);
        if (res.data.data.length == 0) {
          this.flag = true;
        }

        // 手动添加 ischecked判断是否选中
        this.infoList.map((item) => {
          item.ischecked = false;
        });
      } else {
        this.$message.error(res.message);
      }
    },
    //获取菜单列表
    async getMenuList() {
      let data = {
        id: 8,
      };
      let res = await list(qs.stringify(data));
      this.btnArr = res.data;
    },
    //筛选排序
    handleClick(item) {
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
      if (item.title == "时间") {
        if (item.sort) {
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "create_time";
          this.getList();
        } else {
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "create_time";
          this.getList();
        }
      }
      if (item.title == "权威") {
        if (item.sort) {
          this.pageNum = 0;
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "authoritative";
          this.getList();
        } else {
          this.pageNum = 0;
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "authoritative";
          this.getList();
        }
      }
      if (item.title == "热度") {
        if (item.sort) {
          this.pageNum = 0;
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "hot";
          this.getList();
        } else {
          this.pageNum = 0;
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "hot";
          this.getList();
        }
      }
    },
    //选择页数
    currentChange(i) {
      this.pageSize = i;
      this.pageNum = this.pageSize - 1;
      this.getList();
    },
    // 跳转全文页
    handleAllText(item) {
      this.$router.push({
        name: "AllText",
        query: {
          id: item.docmd5,
          fileName: item.title,
        },
      });
    },
    // 全选
    handleCheckAllChange() {
      this.checkarr = [];
      this.checked = !this.checked;
      if (this.checked) {
        this.infoList.map((item) => {
          item.ischecked = true;
          this.checkarr.push(item.docmd5);
        });
      } else {
        this.infoList.map((item) => {
          item.ischecked = false;
        });
        this.checkarr = [];
      }
      this.$forceUpdate();
    },
    // 标准版单选
    handleChecked(item) {
      item.ischecked = !item.ischecked;
      if (item.ischecked) {
        this.checkarr.push(item.docmd5);
      } else {
        this.checkarr.pop();
      }
      this.$forceUpdate();
    },
    // 添加汇编
    async addAssembly() {
      if (this.checkarr.length == 0) {
        this.$message({
          type: "error",
          message: "请选择文章！",
        });
        return;
      }
      let data = [];
      this.checkarr.forEach((item) => {
        let obj = {};
        obj.docId = item;
        obj.docType = 1;
        data.push(obj);
      });
      const res = await addAssemblys(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: `添加成功，您还可以添加 ${res.data} 篇文章`,
        });
        this.getAssemblyNum();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //点击汇编成册,弹窗
    addCompileVolume() {
      if (this.assemblyNum == 0) {
        this.$message({
          type: "error",
          message: "请先添加汇编",
        });
      } else {
        this.compileVolumeDialog = true;
      }
    },
    //执行汇编成册
    async handleCompileVolume() {
      this.$store.commit("playLifeLoading", true);
      const res = await writerMergeWord();
      this.$store.commit("playLifeLoading", false);
      if (res) {
        this.$message({
          type: "success",
          message: "汇编成功",
        });
        this.compileVolumeDialog = false;
        this.getAssemblyNum();
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", "汇编成册.docx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
    },
    // 获取汇编成册数量
    async getAssemblyNum() {
      const res = await getAssemblyNums();
      if (res.code === 200) {
        this.assemblyNum = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //清空汇编
    async cleanCompile() {
      this.$store.commit("playLifeLoading", true);
      const res = await delDocTempInfo();
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "清空成功",
        });
        this.getAssemblyNum();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 单文件下载
    async singleDownload(item) {
      let form = new FormData();
      form.append("md5", item.docmd5);
      // form.append("fileName", item.title);
      // // console.log(item);
      const res = await downloadFile(form);
      const blob = new Blob([res], {
        type: "application/octet-stream",
      });
      const fileName = item.title + ".docx";
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    //批量下载打包zip下载
    async batchDownload() {
      if (this.checkarr.length == 0) {
        this.$message({
          type: "error",
          message: "请选择文件！",
        });
        return;
      }
      this.$store.commit("playLifeLoading", true);
      let data = {};
      let arr = [];
      this.checkarr.forEach((item) => {
        let obj = {};
        obj.docId = item;
        obj.docType = 1; // 1是标准版  2是溯源版
        arr.push(obj);
        data.docInfoDTOList = arr;
      });
      const res = await fileDownload(data);
      if (res) {
        const blob = new Blob([res], {
          type: "application/zip",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", "批量下载文档.zip");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
      this.$store.commit("playLifeLoading", false);
    },
    // 获取网盘列表
    async moveTo(checkData, item) {
      this.md5 = item.docmd5;
      this.checkData = checkData;
      this.movePopup = true;
      const data = {
        id: 0,
      };
      const res = await directory(data);
      this.TreeData = res.data;
    },
    handleNodeClick(data) {
      this.moveid = data.id;
    },
    // 转存网盘确定
    async handleSure() {
      const formData = new FormData();
      formData.append("textMd5", this.md5);
      formData.append("id", this.moveid);
      const res = await saveNetdisk(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "转存成功！",
        });
        this.movePopup = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    test(node, data) {
      switch (data.filetype) {
        case 0:
          if (data.isDeleted == -1) {
            data.picture = "01";
          } else {
            data.picture = "03";
          }
          break;
      }
    },
  },
  created() {
    this.getList();
    this.getMenuList();
    this.getAssemblyNum();
  },
};
</script>

<style lang="scss">
.el-tooltip__popper {
  background: #fff !important;
  color: #3683f2 !important;
  border: 1px solid #4588ff !important;
}
</style>
<style lang="scss" scoped>
::v-deep .el-button {
  height: 30px;
  background: #f3f4f6;
  border: none;
  color: #4587ff;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  padding: 0px;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.color {
  color: #ff5500 !important;
}

.eltree {
  margin-top: -10px;
  width: 310px;
  height: 450px;
  overflow: auto;
}

.infoMian {
  position: fixed;
  z-index: 20;
  top: 450px;
  left: 350px;
  width: 310px;
  height: 500px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  transform: translate(-50%, -50%);
  border: 0px solid #818790;
  box-shadow: 0px 3px 15px 15px rgba(98, 102, 107, 0.16);
  border-radius: 5px;

  .title {
    margin-top: 10px;
    margin-left: 20px;
    color: #333;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
  }

  .xian {
    width: 280px;
    height: 10px;
    border-top: 1px solid #aaa;
    margin: 15px auto;
  }

  .cancel {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 10px;
    height: 10px;
    color: #c7cbd3;
    cursor: pointer;
  }
}

.item {
  color: #3683f2;
  font-size: $font-size-small;
  font-family: PingFang SC;
  font-weight: 400;
}

.move {
  animation: move 1s;
}

@keyframes move {
  10% {
    bottom: -5px;
  }

  30% {
    bottom: 5px;
  }

  50% {
    bottom: -5px;
  }

  70% {
    bottom: 5px;
  }

  90% {
    bottom: -5px;
  }

  100% {
    bottom: 0px;
  }
}

.kong {
  width: 940px;
  height: 440px;
  text-align: center;

  img {
    margin-top: 100px;
    width: 150px;
    height: 150px;
  }

  div {
    margin-top: 10px;
    text-align: center;
    color: #999;
    font-size: 16px;
  }
}

.infocompileIndex {
  height: 100%;
  position: relative;

  &-title {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .infocompileIndex-title-item {
      cursor: pointer;
      font-size: $font-size-medium;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-text-gray-s;
    }

    .infocompileIndex-title-item:hover {
      color: $color-text-active;
    }
  }

  &-content {
    width: 860px;
    margin: 20px auto 0;

    &-searchBox {
      height: 48px;
      display: flex;
      align-items: center;

      &-left {
        &-item {
          display: flex;
          margin-top: 15px;
          cursor: pointer;

          .infocompileIndex-content-searchBox-left-icon {
            width: 12px;
            height: 12px;
            margin-right: 10px;
          }

          .atlas {
            @include backgroundGroup("~assets/images/atlas.png");
          }

          .tree {
            @include backgroundGroup("~assets/images/tree.png");
          }

          .infocompileIndex-content-searchBox-left-text {
            font-size: $font-size-small;
            font-family: PingFang SC;
            font-weight: 400;
            color: $color-text-gray;
          }

          .fontColor {
            color: $color-text-black;
          }
        }
      }

      &-center {
        margin-left: 48px;
        width: 654px;
        border-radius: 5px;
        border: 1px solid #3683f2;
        display: flex;
        align-items: center;

        ::v-deep .el-select {
          width: 85px;

          .el-input__inner {
            border: none;
          }
        }

        input {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-gray;
          width: 487px;
        }

        .infocompileIndex-content-searchBox-btn {
          width: 62px;
          height: 48px;
          background: #3683f2;
          @include flex-center();
          border-radius: 0 5px 5px 0;
          cursor: pointer;

          .infocompileIndex-content-searchBox-btn-icon {
            width: 20px;
            height: 20px;
            @include backgroundGroup("~assets/images/searchIcon.png");
          }
        }
      }
    }

    &-breadcrumb {
      margin: 11px 0 0 100px;
      display: flex;

      .infocompileIndex-content-breadcrumb-item {
        display: flex;
        height: 30px;
        background: #f3f4f6;
        border-radius: 15px;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;

        span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #4587ff;
        }

        .item-title {
          margin-left: 15px;
          width: 56px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .item-num {
          margin-left: 5px;
        }

        i {
          margin-left: 5px;
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    &-list {
      margin-top: 30px;

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-left {
          display: flex;
          align-items: center;

          .introduce-checkBox {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
            .introduce-checkBox-icon {
              width: 16px;
              height: 16px;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url("~assets/images/info/checkBox.png");
              margin-right: 5px;
            }

            .checkBox-checked {
              background-image: url("~assets/images/info/checkBox-checked.png");
            }
          }

          .left-item {
            display: flex;
            margin-left: 30px;
            cursor: pointer;
            &:nth-of-type(4) {
              margin-left: 50px;
            }
            ::v-deep .el-badge__content.is-fixed {
              top: 50%;
              right: 0px;
            }

            .left-item-icon {
              width: 14px;
              height: 14px;
              margin-right: 6px;
            }

            .icon {
              @include backgroundGroup("~assets/images/infoCompile-icon.png");
            }

            .add {
              @include backgroundGroup("~assets/images/infoCompile-add.png");
            }
            .collect {
              @include backgroundGroup("~assets/images/info/collectIcon.png");
            }
            font-size: 14px;

            .download {
              @include backgroundGroup(
                "~assets/images/infoCompile-download.png"
              );
            }
          }
        }

        &-right {
          display: flex;
          align-items: center;

          .right-item {
            display: flex;
            margin-right: 40px;
            cursor: pointer;

            .right-item-text {
              font-size: $font-size-medium;
              font-family: PingFang SC;
              font-weight: 500;
              color: $color-text-black;
            }

            .right-item-icon {
              width: 9px;
              height: 13px;
              margin-left: 6px;
            }

            .up {
              @include backgroundGroup("~assets/images/up.png");
            }

            .down {
              @include backgroundGroup("~assets/images/down.png");
            }

            .fontColor {
              color: #3683f2;
            }
          }

          .right-item:last-of-type {
            margin-right: 0px;
          }
        }
      }

      &-content {
        margin-top: 20px;

        .content-item {
          width: 860px;
          position: relative;
          height: 225px;

          .content-item-icon {
            position: absolute;
            left: 0px;
            top: 10px;
            width: 34px;
            height: 30px;
            display: none;
          }

          .reprint {
            @include backgroundGroup("~assets/images/info/reprint.png");
          }

          .starting {
            @include backgroundGroup("~assets/images/info/starting.png");
          }

          .content-item-like {
            position: absolute;
            right: 22px;
            top: 34px;
            width: 17px;
            height: 16px;
            cursor: pointer;
          }

          .like {
            @include backgroundGroup("~assets/images/info/like.png");
          }

          .like-red {
            @include backgroundGroup("~assets/images/info/like-red.png");
          }

          .content-item-text {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 26px;
            padding: 30px 62px 30px 38px;

            span:last-of-type {
              color: #3683f2;
              cursor: pointer;
              text-decoration: underline;
            }

            .fontBlue {
              color: #3683f2;
            }
          }

          .content-item-introduce {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            margin: 0 62px 0 38px;
            padding-bottom: 20px;
            border-bottom: 1px solid #efefef;

            .introduce-checkBox {
              display: flex;
              align-items: center;
              cursor: pointer;

              .introduce-checkBox-icon {
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("~assets/images/info/checkBox.png");
                margin-right: 5px;
              }

              .checkBox-checked {
                background-image: url("~assets/images/info/checkBox-checked.png");
              }
            }

            .introduce-radioBox {
              margin-left: 15px;
              display: flex;
              align-items: center;
              cursor: pointer;

              .introduce-radioBox-icon {
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("~assets/images/info/radio.png");
                margin-right: 4px;
              }

              .radioBox-checked {
                background-image: url("~assets/images/info/radio-checked.png");
              }
            }

            .introduce-starBox {
              margin-left: 14px;
              display: flex;
              align-items: center;

              .introduce-starBox-icon {
                width: 14px;
                height: 14px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("~assets/images/info/star.png");
                margin-right: 5px;
              }
            }

            .introduce-hotBox {
              margin-left: 18px;
              display: flex;
              align-items: center;

              .introduce-hotBox-icon {
                width: 14px;
                height: 14px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("~assets/images/info/hot.png");
                margin-right: 5px;
              }
            }

            .introduce-lampBox {
              margin-left: 19px;
              display: flex;
              align-items: center;

              .introduce-lampBox-icon {
                width: 14px;
                height: 14px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("~assets/images/info/lamp.png");
                margin-right: 4px;
              }
            }

            .introduce-timeBox {
              margin-left: 21px;
              display: flex;
              align-items: center;

              .introduce-timeBox-icon {
                width: 14px;
                height: 14px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("~assets/images/info/time.png");
                margin-right: 4px;
              }

              .introduce-timeBox-text {
                width: 85px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .introduce-titleBox-text {
              width: 270px;
              margin-left: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .content-item-btnGroup {
            display: flex;
            align-items: center;
            height: 55px;
            justify-content: space-around;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            display: none;
            padding: 0 50px;
            .btnGroup-line {
              width: 1px;
              height: 30px;
              background: #d8d8d8;
            }

            .content-item-btnItem {
              cursor: pointer;
              display: flex;
              align-items: center;
              height: 30px;
              img {
                margin-right: 3px;
              }

              .content-item-btnItem-icon {
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 5px;
              }

              .download {
                background-image: url("~assets/images/info/download.png");
              }

              .upload {
                background-image: url("~assets/images/info/upload.png");
              }

              .relation {
                background-image: url("~assets/images/info/relation.png");
              }

              .index {
                background-image: url("~assets/images/info/index.png");
              }

              .copyright {
                background-image: url("~assets/images/info/copyright.png");
              }

              .share {
                background-image: url("~assets/images/info/share.png");
              }
            }

            .downloadBox:hover .download {
              background-image: url("~assets/images/info/download-red.png");
            }

            .uploadBox:hover .upload {
              background-image: url("~assets/images/info/upload-red.png");
            }

            .relationBox:hover .relation {
              background-image: url("~assets/images/info/relation-red.png");
            }

            .indexBox:hover .index {
              background-image: url("~assets/images/info/index-red.png");
            }

            .copyrightBox:hover .copyright {
              background-image: url("~assets/images/info/copyright-red.png");
            }

            .shareBox:hover .share {
              background-image: url("~assets/images/info/share-red.png");
            }

            .content-item-btnItem:hover .content-item-btnItem-text {
              color: #ec5e58;
            }
          }
        }

        .content-item:hover {
          background: #ffffff;
          box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
          border-radius: 5px;
        }

        .content-item:hover .content-item-icon {
          display: block;
        }

        .content-item:hover .content-item-btnGroup {
          display: flex;
        }
      }
    }
  }

  &-footer {
    @include noselect;
    margin: 30px 0;
    display: flex;
    justify-content: center;

    ::v-deep .el-pagination {
      button {
        width: 80px;
      }
    }
  }

  .addFil {
    ::v-deep .el-dialog {
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .footer-btn {
            width: 71px;
            height: 41px;
            border: 1px solid #cecece;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            background: #fff;
          }

          .sure {
            margin-right: 12px;
            background: #4587ff;
            color: #fff;
          }
        }
      }
    }
  }
  .compile-volume {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 340px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 35px 30;
        .content {
          @include flex-center;
          flex: 1;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;
          padding: 40px;
          .footer-btn {
            @include noselect;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
            &.clear {
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>
